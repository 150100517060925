@import 'config/variables';

$link-color_base-hover: $color_primary-500;
$links-block-addto_hover-color: $color_primary-500;

@import '../../../../theme-creativeshop/src/components/links-block-addto/links-block-addto.scss';

.#{$ns}links-block-addto {
    $root: &;

    &__item {
        &--wishlist {
            a,
            a:visited,
            a:hover,
            .alink {
                color: $color_black;
            }
        }
    }

}
