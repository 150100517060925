@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/reset-list-style';

@import '../../../../theme-creativeshop/src/components/social-media-list/social-media-list.scss';

.#{$ns}social-media-list {
    $root: &;

    &__icon {
        #{$root}__item--insta & {
            width: 2.1em;
            height: 2.1em;
        }
        #{$root}__item--pint & {
            width: 1.56em;
            height: 2.1em;
        }
    }

    &__link {
        color: $color_text-200;
    }
}
