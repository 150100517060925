@import 'config/variables';
@import 'vendors/include-media';
@import 'components/badge/mixin';
@import 'components/price-box/variables.scss';
@import 'etc/view.scss';
@import 'utils/deep-get/deep-get.scss';
@import 'utils/aspect-ratio';
@import 'utils/string-to-number';
@import 'utils/reset-list-style';
@import 'utils/visually-hidden';

$produdct-tile_addto-font-size: 1.2rem;

@import '../../../../theme-creativeshop/src/components/product-tile/product-tile.scss';

.#{$ns}product-tile {
    $root: &;

    &__swatches {
        display: none;
    }

}
