@import 'config/variables';

$header_border-bottom: 0;

@import '../../../../theme-creativeshop/src/components/header/header.scss';

.#{$ns}header {
    &__content {
        background-color: $color_gallery;
    }
    &__search {
        background-color: $color_gallery;
    }
}
