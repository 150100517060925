/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-google-recaptcha {
  margin: 1rem 0; }
  .cs-google-recaptcha__badge--invisible .grecaptcha-badge {
    visibility: hidden;
    height: 0 !important; }
  .cs-google-recaptcha__note {
    display: inline-block;
    margin: 0;
    font-size: 1.2rem;
    color: #000; }
    .cs-google-recaptcha__note a, .cs-google-recaptcha__note a:visited {
      color: #324dcf;
      text-decoration: none; }
    .cs-google-recaptcha__note a:hover, .cs-google-recaptcha__note a:focus, .cs-google-recaptcha__note a:active, .cs-google-recaptcha__note a:visited:hover, .cs-google-recaptcha__note a:visited:focus {
      color: #142b9e;
      text-decoration: none; }
  .cs-google-recaptcha--newsletter {
    display: none;
    margin: 1rem 0; }
    .cs-google-recaptcha--newsletter .cs-google-recaptcha__note {
      margin: 0;
      font-size: 1.2rem;
      color: #fff; }
      .cs-google-recaptcha--newsletter .cs-google-recaptcha__note a, .cs-google-recaptcha--newsletter .cs-google-recaptcha__note a:visited {
        color: #fff;
        text-decoration: none; }
      .cs-google-recaptcha--newsletter .cs-google-recaptcha__note a:hover, .cs-google-recaptcha--newsletter .cs-google-recaptcha__note a:focus, .cs-google-recaptcha--newsletter .cs-google-recaptcha__note a:active, .cs-google-recaptcha--newsletter .cs-google-recaptcha__note a:visited:hover, .cs-google-recaptcha--newsletter .cs-google-recaptcha__note a:visited:focus {
        color: #fff;
        text-decoration: underline; }
  .cs-google-recaptcha--customer_create {
    margin-top: -2rem; }
  .cs-google-recaptcha--customer_login .g-recaptcha {
    margin-bottom: 0 !important; }
