@import 'config/variables';
@import 'vendors/include-media';
@import 'components/typography/mixin';

$topbar_item-font-size: 1.4rem;
$topbar_height: 3.7rem;

@import '../../../../theme-creativeshop/src/components/topbar/topbar.scss';

.#{$ns}topbar {
    &__item {
        &--authorization {
            display: none;
        }
    }
}
