@import 'config/variables';
@import 'components/typography/mixin';

@import '../../../../theme-creativeshop/src/components/typography/typography.scss';

a,
a:visited,
a:hover,
.alink {
    color: $color_primary-500;
}
