@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/reset-list-style';
@import 'components/pseudoicon/mixin';


@import '../../../../theme-creativeshop/src/components/dropdown-switcher/dropdown-switcher.scss';

.#{$ns}dropdown-switcher {
    $root: &;

    &__trigger {
        @include media('>=laptop') {
            font-size: 1.4rem;
        }
    }
}
