@import 'config/variables';
@import 'vendors/include-media';

@import '../../../../theme-creativeshop/src/components/logo/logo.scss';

.#{$ns}logo {
    &__image {
        width: 180px;
    }
}
