@import 'config/variables';
@import 'vendors/include-media';

$offcanvas_vertical-offset-mobile: $global_header-height-mobile !default; // Move below the navigation on mobile.
$offcanvas_top-offset-tablet: $global_header-height-tablet !default; // Move below the navigation on tablet.
$offcanvas_transition-time: $transition-default-time !default;

$offcanvas_overlay-background: $modals_overlay-background !default;

$offcanvas_drawer-background: $color_background-200 !default;

$offcanvas_spinner-color: $color_primary-500 !default;

.#{$ns}offcanvas {
    $root: &;

    position: relative;
    z-index: 300;

    &__drawer,
    &__overlay {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;

        @if ($global_header-is-bottom-on-mobile) {
            bottom: $offcanvas_vertical-offset-mobile;
        } @else {
            top: $offcanvas_vertical-offset-mobile;
        }

        @include media('>=tablet') {
            top: $offcanvas_top-offset-tablet;
            bottom: 0;
        }

        @include media('>=laptop') {
            display: none;
        }

        #{$root}--mini-cart & {
            display: block;
            top: 0;
        }
    }

    &__overlay {
        width: 100%;
        height: calc(100% - #{$offcanvas_vertical-offset-mobile});
        background-color: transparent;
        // Setting 1ms because 0 doesn't seem to work and we need seamless transition for scale here.
        transition: background-color $offcanvas_transition-time,
            transform 1ms $offcanvas_transition-time;
        transform: scale(0);
        cursor: pointer;

        @include media('>=tablet') {
            height: 100%;
        }

        #{$root}--mini-cart & {
            height: 100%;
        }
    }

    .overlay {
        &--visible {
            transform: scale(1);
            background: $offcanvas_overlay-background;
            transition: background-color $offcanvas_transition-time;
        }
    }

    &__drawer {
        position: fixed;
        background: $offcanvas_drawer-background;
        transition: transform $offcanvas_transition-time;
        width: 90%;
        max-width: 40rem;

        @if ($global_header-is-bottom-on-mobile) {
            bottom: $offcanvas_vertical-offset-mobile;

            @include media('>=tablet') {
                bottom: 0;
            }
        }

        #{$root}--side_left & {
            left: 0;
            right: auto;
            transform: translate3d(-105%, 0, 0);
        }

        #{$root}--side_right & {
            left: auto;
            right: 0;
            transform: translate3d(105%, 0, 0);
        }

        &--navigation {
            @if ($global_header-mobile-menu-is-right) {
                left: auto;
                right: 0;
                transform: translate3d(105%, 0, 0);
            } @else {
                left: 0;
                right: auto;
                transform: translate3d(-105%, 0, 0);
            }
        }
    }

    .drawer {
        &--visible {
            transform: translate3d(0, 0, 0) !important;
        }
    }

    &__spinner {
        position: absolute;
        width: 6rem;
        height: 6rem;
        left: 50%;
        top: 20%;
        margin-left: -3rem;
    }
}
