@import 'config/variables';
@import 'vendors/include-media';

.#{$ns}logo {
    height: 100%;

    &__image {
        height: 100%; // Fixes height: auto bug on chrome.

        &--desktop {
            display: none;

            .checkout-index-index & {
                display: block;
            }

            @include media('>=tablet') {
                display: block;
            }
        }

        &--mobile {
            display: block;

            @include media('>=tablet') {
                display: none;
            }

            .checkout-index-index & {
                display: none;
            }
        }
    }
}
