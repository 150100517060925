/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-pwa-notification-panel {
  display: none; }
  @media (min-width: 48em) {
    .cs-pwa-notification-panel {
      flex-wrap: nowrap; } }
  .cs-pwa-notification-panel--request .cs-pwa-notification-panel__icon--request {
    display: block; }
  .cs-pwa-notification-panel--request .cs-pwa-notification-panel__message--request {
    display: flex; }
  .cs-pwa-notification-panel--request .cs-pwa-notification-panel__action--request {
    display: block; }
  .cs-pwa-notification-panel--browser .cs-pwa-notification-panel__icon--browser {
    display: block; }
  .cs-pwa-notification-panel--browser .cs-pwa-notification-panel__message--browser {
    display: flex; }
  .cs-pwa-notification-panel--browser .cs-pwa-notification-panel__action--browser {
    display: block; }
  .cs-pwa-notification-panel--enabled .cs-pwa-notification-panel__icon--enabled {
    display: block; }
  .cs-pwa-notification-panel--enabled .cs-pwa-notification-panel__message--enabled {
    display: flex; }
  .cs-pwa-notification-panel__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    background: #fff;
    padding: 2rem 1.5rem;
    border: none;
    border-radius: 3px;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1), -2px -2px 3px rgba(0, 0, 0, 0.1);
    margin: 1.5rem 0; }
  .cs-pwa-notification-panel__icon-wrapper {
    margin: 0 1.5rem; }
    @media (max-width: 29.99em) {
      .cs-pwa-notification-panel__icon-wrapper {
        display: none; } }
  .cs-pwa-notification-panel__icon {
    display: none;
    width: 3.5rem;
    height: 3.5rem; }
    .cs-pwa-notification-panel__icon--animate {
      animation: ringing 6s infinite; }
  .cs-pwa-notification-panel__message-wrapper {
    margin: 0 1.5rem;
    flex: 1; }
    @media (max-width: 47.99em) {
      .cs-pwa-notification-panel__message-wrapper {
        padding-right: 3rem; } }
  .cs-pwa-notification-panel__message {
    display: none;
    flex-direction: column; }
    .cs-pwa-notification-panel__message--request .cs-pwa-notification-panel__message-title {
      color: #000; }
    .cs-pwa-notification-panel__message--browser .cs-pwa-notification-panel__message-title {
      color: #000; }
    .cs-pwa-notification-panel__message--enabled .cs-pwa-notification-panel__message-title {
      color: #31a43a; }
    .cs-pwa-notification-panel__message-title {
      font-size: 2rem;
      font-weight: 700; }
    .cs-pwa-notification-panel__message-description {
      font-size: 1.4rem;
      font-weight: 400;
      color: #000; }
  .cs-pwa-notification-panel__actions {
    margin: 0 1.5rem; }
    @media (max-width: 47.99em) {
      .cs-pwa-notification-panel__actions {
        display: flex;
        justify-content: center;
        flex: 1 1 100%;
        margin-top: 2rem; } }
  .cs-pwa-notification-panel__action {
    display: none; }
    .cs-pwa-notification-panel__action--browser {
      width: 30rem; }
      @media (max-width: 47.99em) {
        .cs-pwa-notification-panel__action--browser {
          width: 100%;
          max-width: 30rem; } }
  @media (max-width: 29.99em) {
    .cs-pwa-notification-panel__button {
      width: 100%; } }
  .cs-pwa-notification-panel__button:first-of-type {
    margin: 0 0 0.5rem; }
    @media (min-width: 30em) {
      .cs-pwa-notification-panel__button:first-of-type {
        margin: 0 1.5rem 0; } }
  .cs-pwa-notification-panel__button--accept {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    will-change: transform, background-color, border-color, fill, color; }
    .cs-pwa-notification-panel__button--accept::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-pwa-notification-panel__button--accept, .cs-pwa-notification-panel__button--accept:visited {
      cursor: pointer;
      border-radius: 3px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #7A5B05; }
      .cs-pwa-notification-panel__button--accept *, .cs-pwa-notification-panel__button--accept:visited * {
        color: #fff;
        fill: #fff; }
    .cs-pwa-notification-panel__button--accept:hover:not([disabled]), .cs-pwa-notification-panel__button--accept:focus:not([disabled]), .cs-pwa-notification-panel__button--accept:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #7A5B05; }
      .cs-pwa-notification-panel__button--accept:hover:not([disabled]) *, .cs-pwa-notification-panel__button--accept:focus:not([disabled]) *, .cs-pwa-notification-panel__button--accept:active:not([disabled]) * {
        color: #fff;
        fill: #fff; }
    .cs-pwa-notification-panel__button--accept[disabled] {
      opacity: 0.65;
      cursor: auto; }
    .cs-pwa-notification-panel__button--accept .cs-pwa-notification-panel__button-span {
      margin-top: 0;
      z-index: 1;
      font-weight: 700;
      vertical-align: baseline;
      display: inline-block;
      line-height: 1.275em;
      letter-spacing: normal; }
    .cs-pwa-notification-panel__button--accept .cs-pwa-notification-panel__button-icon {
      max-width: 1em;
      max-height: 1.4rem;
      z-index: 2;
      height: auto;
      width: 100%;
      display: none; }
  .cs-pwa-notification-panel__button--decline {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    will-change: transform, background-color, border-color, fill, color; }
    .cs-pwa-notification-panel__button--decline::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-pwa-notification-panel__button--decline, .cs-pwa-notification-panel__button--decline:visited {
      cursor: pointer;
      border-radius: 3px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid #7A5B05;
      color: #7A5B05;
      background-color: #fff; }
      .cs-pwa-notification-panel__button--decline *, .cs-pwa-notification-panel__button--decline:visited * {
        color: #7A5B05;
        fill: #7A5B05; }
    .cs-pwa-notification-panel__button--decline:hover:not([disabled]), .cs-pwa-notification-panel__button--decline:focus:not([disabled]), .cs-pwa-notification-panel__button--decline:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid #7A5B05;
      color: #fff;
      background-color: #7A5B05; }
      .cs-pwa-notification-panel__button--decline:hover:not([disabled]) *, .cs-pwa-notification-panel__button--decline:focus:not([disabled]) *, .cs-pwa-notification-panel__button--decline:active:not([disabled]) * {
        color: #fff;
        fill: #fff; }
    .cs-pwa-notification-panel__button--decline[disabled] {
      opacity: 0.65;
      cursor: auto; }
    .cs-pwa-notification-panel__button--decline .cs-pwa-notification-panel__button-span {
      margin-top: 0;
      z-index: 1;
      font-weight: 700;
      vertical-align: baseline;
      display: inline-block;
      line-height: 1.275em;
      letter-spacing: normal; }
    .cs-pwa-notification-panel__button--decline .cs-pwa-notification-panel__button-icon {
      max-width: 1em;
      max-height: 1.4rem;
      z-index: 2;
      height: auto;
      width: 100%;
      display: none; }
  .cs-pwa-notification-panel__permission {
    display: flex;
    flex-direction: column;
    border: none;
    border-radius: 3px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1), -2px -2px 5px rgba(0, 0, 0, 0.1);
    font-size: 1.2rem;
    color: #000;
    padding: 1rem 2rem; }
    .cs-pwa-notification-panel__permission-icon {
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 0.5rem; }
    .cs-pwa-notification-panel__permission-button-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem; }
    .cs-pwa-notification-panel__permission-button {
      padding: 0.2rem 2rem;
      margin: 0 0.2rem;
      border: 1px solid #000;
      color: #000;
      font-size: 1.1rem; }
      .cs-pwa-notification-panel__permission-button--block {
        opacity: 0.3; }
  .cs-pwa-notification-panel__close {
    margin: 0 1.5rem; }
    @media (max-width: 47.99em) {
      .cs-pwa-notification-panel__close {
        position: absolute;
        top: 2rem;
        right: 1.5rem; } }
    .cs-pwa-notification-panel__close-icon {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      fill: #000; }
    .cs-pwa-notification-panel__close:hover .cs-pwa-notification-panel__close-icon {
      fill: #000; }

@keyframes ringing {
  0%,
  12% {
    transform: scale(1) rotate(0deg); }
  3%,
  7% {
    transform: scale(1.3) rotate(27deg); }
  5%,
  9% {
    transform: scale(1.3) rotate(-27deg); } }
