/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable no-descending-specificity */
.modal-slide.confirm .modal-inner-wrap,
.modal-popup.confirm .modal-inner-wrap,
.modal-custom.confirm .modal-inner-wrap {
  max-width: 50rem; }

.modal-slide .modal-inner-wrap,
.modal-popup .modal-inner-wrap,
.modal-custom .modal-inner-wrap {
  background: #fff;
  box-shadow: 1px 3px 3rem 0 rgba(33, 33, 33, 0.28);
  border-radius: 0;
  border: none; }

.modal-slide .modal-content,
.modal-popup .modal-content,
.modal-custom .modal-content {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.4rem; }

.modal-slide .modal-header,
.modal-popup .modal-header,
.modal-custom .modal-header {
  padding: 0;
  margin: 0; }

.modal-slide .action-close,
.modal-popup .action-close,
.modal-custom .action-close {
  background: none;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  margin: 1.5rem;
  background-image: url("../images/icons/close.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1rem 1rem;
  width: 3rem;
  height: 3rem; }
  .ie11 .modal-slide .action-close, .ie11
  .modal-popup .action-close, .ie11
  .modal-custom .action-close {
    background-size: 10px 10px; }
  .modal-slide .action-close span,
  .modal-popup .action-close span,
  .modal-custom .action-close span {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    /* 1 */ }

.modal-slide .modal-footer,
.modal-popup .modal-footer,
.modal-custom .modal-footer {
  padding: 2rem;
  border-top: 1px solid #dcdcdc;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 29.99em) {
    .modal-slide .modal-footer,
    .modal-popup .modal-footer,
    .modal-custom .modal-footer {
      flex-wrap: wrap; } }
  .modal-slide .modal-footer .action-primary,
  .modal-slide .modal-footer .primary,
  .modal-popup .modal-footer .action-primary,
  .modal-popup .modal-footer .primary,
  .modal-custom .modal-footer .action-primary,
  .modal-custom .modal-footer .primary {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    will-change: transform, background-color, border-color, fill, color;
    min-width: 12rem; }
    .modal-slide .modal-footer .action-primary::-moz-focus-inner,
    .modal-slide .modal-footer .primary::-moz-focus-inner,
    .modal-popup .modal-footer .action-primary::-moz-focus-inner,
    .modal-popup .modal-footer .primary::-moz-focus-inner,
    .modal-custom .modal-footer .action-primary::-moz-focus-inner,
    .modal-custom .modal-footer .primary::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .modal-slide .modal-footer .action-primary, .modal-slide .modal-footer .action-primary:visited,
    .modal-slide .modal-footer .primary,
    .modal-slide .modal-footer .primary:visited,
    .modal-popup .modal-footer .action-primary,
    .modal-popup .modal-footer .action-primary:visited,
    .modal-popup .modal-footer .primary,
    .modal-popup .modal-footer .primary:visited,
    .modal-custom .modal-footer .action-primary,
    .modal-custom .modal-footer .action-primary:visited,
    .modal-custom .modal-footer .primary,
    .modal-custom .modal-footer .primary:visited {
      cursor: pointer;
      border-radius: 3px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #7A5B05; }
      .modal-slide .modal-footer .action-primary *, .modal-slide .modal-footer .action-primary:visited *,
      .modal-slide .modal-footer .primary *,
      .modal-slide .modal-footer .primary:visited *,
      .modal-popup .modal-footer .action-primary *,
      .modal-popup .modal-footer .action-primary:visited *,
      .modal-popup .modal-footer .primary *,
      .modal-popup .modal-footer .primary:visited *,
      .modal-custom .modal-footer .action-primary *,
      .modal-custom .modal-footer .action-primary:visited *,
      .modal-custom .modal-footer .primary *,
      .modal-custom .modal-footer .primary:visited * {
        color: #fff;
        fill: #fff; }
    .modal-slide .modal-footer .action-primary:hover:not([disabled]), .modal-slide .modal-footer .action-primary:focus:not([disabled]), .modal-slide .modal-footer .action-primary:active:not([disabled]),
    .modal-slide .modal-footer .primary:hover:not([disabled]),
    .modal-slide .modal-footer .primary:focus:not([disabled]),
    .modal-slide .modal-footer .primary:active:not([disabled]),
    .modal-popup .modal-footer .action-primary:hover:not([disabled]),
    .modal-popup .modal-footer .action-primary:focus:not([disabled]),
    .modal-popup .modal-footer .action-primary:active:not([disabled]),
    .modal-popup .modal-footer .primary:hover:not([disabled]),
    .modal-popup .modal-footer .primary:focus:not([disabled]),
    .modal-popup .modal-footer .primary:active:not([disabled]),
    .modal-custom .modal-footer .action-primary:hover:not([disabled]),
    .modal-custom .modal-footer .action-primary:focus:not([disabled]),
    .modal-custom .modal-footer .action-primary:active:not([disabled]),
    .modal-custom .modal-footer .primary:hover:not([disabled]),
    .modal-custom .modal-footer .primary:focus:not([disabled]),
    .modal-custom .modal-footer .primary:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #7A5B05; }
      .modal-slide .modal-footer .action-primary:hover:not([disabled]) *, .modal-slide .modal-footer .action-primary:focus:not([disabled]) *, .modal-slide .modal-footer .action-primary:active:not([disabled]) *,
      .modal-slide .modal-footer .primary:hover:not([disabled]) *,
      .modal-slide .modal-footer .primary:focus:not([disabled]) *,
      .modal-slide .modal-footer .primary:active:not([disabled]) *,
      .modal-popup .modal-footer .action-primary:hover:not([disabled]) *,
      .modal-popup .modal-footer .action-primary:focus:not([disabled]) *,
      .modal-popup .modal-footer .action-primary:active:not([disabled]) *,
      .modal-popup .modal-footer .primary:hover:not([disabled]) *,
      .modal-popup .modal-footer .primary:focus:not([disabled]) *,
      .modal-popup .modal-footer .primary:active:not([disabled]) *,
      .modal-custom .modal-footer .action-primary:hover:not([disabled]) *,
      .modal-custom .modal-footer .action-primary:focus:not([disabled]) *,
      .modal-custom .modal-footer .action-primary:active:not([disabled]) *,
      .modal-custom .modal-footer .primary:hover:not([disabled]) *,
      .modal-custom .modal-footer .primary:focus:not([disabled]) *,
      .modal-custom .modal-footer .primary:active:not([disabled]) * {
        color: #fff;
        fill: #fff; }
    .modal-slide .modal-footer .action-primary[disabled],
    .modal-slide .modal-footer .primary[disabled],
    .modal-popup .modal-footer .action-primary[disabled],
    .modal-popup .modal-footer .primary[disabled],
    .modal-custom .modal-footer .action-primary[disabled],
    .modal-custom .modal-footer .primary[disabled] {
      opacity: 0.65;
      cursor: auto; }
    @media (max-width: 29.99em) {
      .modal-slide .modal-footer .action-primary,
      .modal-slide .modal-footer .primary,
      .modal-popup .modal-footer .action-primary,
      .modal-popup .modal-footer .primary,
      .modal-custom .modal-footer .action-primary,
      .modal-custom .modal-footer .primary {
        width: 100%; } }
    .modal-slide .modal-footer .action-primary span,
    .modal-slide .modal-footer .primary span,
    .modal-popup .modal-footer .action-primary span,
    .modal-popup .modal-footer .primary span,
    .modal-custom .modal-footer .action-primary span,
    .modal-custom .modal-footer .primary span {
      margin-top: 0;
      z-index: 1;
      font-weight: 700;
      vertical-align: baseline;
      display: inline-block;
      line-height: 1.275em;
      letter-spacing: normal; }
  .modal-slide .modal-footer .action-secondary,
  .modal-slide .modal-footer .secondary,
  .modal-popup .modal-footer .action-secondary,
  .modal-popup .modal-footer .secondary,
  .modal-custom .modal-footer .action-secondary,
  .modal-custom .modal-footer .secondary {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    will-change: transform, background-color, border-color, fill, color;
    min-width: 12rem; }
    .modal-slide .modal-footer .action-secondary::-moz-focus-inner,
    .modal-slide .modal-footer .secondary::-moz-focus-inner,
    .modal-popup .modal-footer .action-secondary::-moz-focus-inner,
    .modal-popup .modal-footer .secondary::-moz-focus-inner,
    .modal-custom .modal-footer .action-secondary::-moz-focus-inner,
    .modal-custom .modal-footer .secondary::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .modal-slide .modal-footer .action-secondary, .modal-slide .modal-footer .action-secondary:visited,
    .modal-slide .modal-footer .secondary,
    .modal-slide .modal-footer .secondary:visited,
    .modal-popup .modal-footer .action-secondary,
    .modal-popup .modal-footer .action-secondary:visited,
    .modal-popup .modal-footer .secondary,
    .modal-popup .modal-footer .secondary:visited,
    .modal-custom .modal-footer .action-secondary,
    .modal-custom .modal-footer .action-secondary:visited,
    .modal-custom .modal-footer .secondary,
    .modal-custom .modal-footer .secondary:visited {
      cursor: pointer;
      border-radius: 3px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid #7A5B05;
      color: #7A5B05;
      background-color: #fff; }
      .modal-slide .modal-footer .action-secondary *, .modal-slide .modal-footer .action-secondary:visited *,
      .modal-slide .modal-footer .secondary *,
      .modal-slide .modal-footer .secondary:visited *,
      .modal-popup .modal-footer .action-secondary *,
      .modal-popup .modal-footer .action-secondary:visited *,
      .modal-popup .modal-footer .secondary *,
      .modal-popup .modal-footer .secondary:visited *,
      .modal-custom .modal-footer .action-secondary *,
      .modal-custom .modal-footer .action-secondary:visited *,
      .modal-custom .modal-footer .secondary *,
      .modal-custom .modal-footer .secondary:visited * {
        color: #7A5B05;
        fill: #7A5B05; }
    .modal-slide .modal-footer .action-secondary:hover:not([disabled]), .modal-slide .modal-footer .action-secondary:focus:not([disabled]), .modal-slide .modal-footer .action-secondary:active:not([disabled]),
    .modal-slide .modal-footer .secondary:hover:not([disabled]),
    .modal-slide .modal-footer .secondary:focus:not([disabled]),
    .modal-slide .modal-footer .secondary:active:not([disabled]),
    .modal-popup .modal-footer .action-secondary:hover:not([disabled]),
    .modal-popup .modal-footer .action-secondary:focus:not([disabled]),
    .modal-popup .modal-footer .action-secondary:active:not([disabled]),
    .modal-popup .modal-footer .secondary:hover:not([disabled]),
    .modal-popup .modal-footer .secondary:focus:not([disabled]),
    .modal-popup .modal-footer .secondary:active:not([disabled]),
    .modal-custom .modal-footer .action-secondary:hover:not([disabled]),
    .modal-custom .modal-footer .action-secondary:focus:not([disabled]),
    .modal-custom .modal-footer .action-secondary:active:not([disabled]),
    .modal-custom .modal-footer .secondary:hover:not([disabled]),
    .modal-custom .modal-footer .secondary:focus:not([disabled]),
    .modal-custom .modal-footer .secondary:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid #7A5B05;
      color: #fff;
      background-color: #7A5B05; }
      .modal-slide .modal-footer .action-secondary:hover:not([disabled]) *, .modal-slide .modal-footer .action-secondary:focus:not([disabled]) *, .modal-slide .modal-footer .action-secondary:active:not([disabled]) *,
      .modal-slide .modal-footer .secondary:hover:not([disabled]) *,
      .modal-slide .modal-footer .secondary:focus:not([disabled]) *,
      .modal-slide .modal-footer .secondary:active:not([disabled]) *,
      .modal-popup .modal-footer .action-secondary:hover:not([disabled]) *,
      .modal-popup .modal-footer .action-secondary:focus:not([disabled]) *,
      .modal-popup .modal-footer .action-secondary:active:not([disabled]) *,
      .modal-popup .modal-footer .secondary:hover:not([disabled]) *,
      .modal-popup .modal-footer .secondary:focus:not([disabled]) *,
      .modal-popup .modal-footer .secondary:active:not([disabled]) *,
      .modal-custom .modal-footer .action-secondary:hover:not([disabled]) *,
      .modal-custom .modal-footer .action-secondary:focus:not([disabled]) *,
      .modal-custom .modal-footer .action-secondary:active:not([disabled]) *,
      .modal-custom .modal-footer .secondary:hover:not([disabled]) *,
      .modal-custom .modal-footer .secondary:focus:not([disabled]) *,
      .modal-custom .modal-footer .secondary:active:not([disabled]) * {
        color: #fff;
        fill: #fff; }
    .modal-slide .modal-footer .action-secondary[disabled],
    .modal-slide .modal-footer .secondary[disabled],
    .modal-popup .modal-footer .action-secondary[disabled],
    .modal-popup .modal-footer .secondary[disabled],
    .modal-custom .modal-footer .action-secondary[disabled],
    .modal-custom .modal-footer .secondary[disabled] {
      opacity: 0.65;
      cursor: auto; }
    @media (max-width: 29.99em) {
      .modal-slide .modal-footer .action-secondary,
      .modal-slide .modal-footer .secondary,
      .modal-popup .modal-footer .action-secondary,
      .modal-popup .modal-footer .secondary,
      .modal-custom .modal-footer .action-secondary,
      .modal-custom .modal-footer .secondary {
        width: 100%;
        margin-bottom: 1.5rem !important; } }
    .modal-slide .modal-footer .action-secondary span,
    .modal-slide .modal-footer .secondary span,
    .modal-popup .modal-footer .action-secondary span,
    .modal-popup .modal-footer .secondary span,
    .modal-custom .modal-footer .action-secondary span,
    .modal-custom .modal-footer .secondary span {
      margin-top: 0;
      z-index: 1;
      font-weight: 700;
      vertical-align: baseline;
      display: inline-block;
      line-height: 1.275em;
      letter-spacing: normal; }
    body.checkout-index-index .modal-slide .modal-footer .action-secondary, body.checkout-index-index
    .modal-slide .modal-footer .secondary, body.checkout-index-index
    .modal-popup .modal-footer .action-secondary, body.checkout-index-index
    .modal-popup .modal-footer .secondary, body.checkout-index-index
    .modal-custom .modal-footer .action-secondary, body.checkout-index-index
    .modal-custom .modal-footer .secondary {
      display: inline-block;
      font-size: 1.4rem;
      font-weight: 700;
      text-align: center;
      position: relative;
      line-height: 1.275em;
      margin: 0;
      text-transform: none;
      padding: 0.75em 1em;
      box-sizing: border-box;
      transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
      vertical-align: middle;
      z-index: 5;
      will-change: transform, background-color, border-color, fill, color;
      min-width: 12rem; }
      body.checkout-index-index .modal-slide .modal-footer .action-secondary::-moz-focus-inner, body.checkout-index-index
      .modal-slide .modal-footer .secondary::-moz-focus-inner, body.checkout-index-index
      .modal-popup .modal-footer .action-secondary::-moz-focus-inner, body.checkout-index-index
      .modal-popup .modal-footer .secondary::-moz-focus-inner, body.checkout-index-index
      .modal-custom .modal-footer .action-secondary::-moz-focus-inner, body.checkout-index-index
      .modal-custom .modal-footer .secondary::-moz-focus-inner {
        border: 0;
        padding: 0;
        margin: 0; }
      body.checkout-index-index .modal-slide .modal-footer .action-secondary, body.checkout-index-index .modal-slide .modal-footer .action-secondary:visited, body.checkout-index-index
      .modal-slide .modal-footer .secondary, body.checkout-index-index
      .modal-slide .modal-footer .secondary:visited, body.checkout-index-index
      .modal-popup .modal-footer .action-secondary, body.checkout-index-index
      .modal-popup .modal-footer .action-secondary:visited, body.checkout-index-index
      .modal-popup .modal-footer .secondary, body.checkout-index-index
      .modal-popup .modal-footer .secondary:visited, body.checkout-index-index
      .modal-custom .modal-footer .action-secondary, body.checkout-index-index
      .modal-custom .modal-footer .action-secondary:visited, body.checkout-index-index
      .modal-custom .modal-footer .secondary, body.checkout-index-index
      .modal-custom .modal-footer .secondary:visited {
        cursor: pointer;
        border-radius: 3px;
        outline-offset: 1px;
        text-decoration: none;
        box-shadow: none;
        border: 1px solid #7A5B05;
        color: #7A5B05;
        background-color: #fff; }
        body.checkout-index-index .modal-slide .modal-footer .action-secondary *, body.checkout-index-index .modal-slide .modal-footer .action-secondary:visited *, body.checkout-index-index
        .modal-slide .modal-footer .secondary *, body.checkout-index-index
        .modal-slide .modal-footer .secondary:visited *, body.checkout-index-index
        .modal-popup .modal-footer .action-secondary *, body.checkout-index-index
        .modal-popup .modal-footer .action-secondary:visited *, body.checkout-index-index
        .modal-popup .modal-footer .secondary *, body.checkout-index-index
        .modal-popup .modal-footer .secondary:visited *, body.checkout-index-index
        .modal-custom .modal-footer .action-secondary *, body.checkout-index-index
        .modal-custom .modal-footer .action-secondary:visited *, body.checkout-index-index
        .modal-custom .modal-footer .secondary *, body.checkout-index-index
        .modal-custom .modal-footer .secondary:visited * {
          color: #7A5B05;
          fill: #7A5B05; }
      body.checkout-index-index .modal-slide .modal-footer .action-secondary:hover:not([disabled]), body.checkout-index-index .modal-slide .modal-footer .action-secondary:focus:not([disabled]), body.checkout-index-index .modal-slide .modal-footer .action-secondary:active:not([disabled]), body.checkout-index-index
      .modal-slide .modal-footer .secondary:hover:not([disabled]), body.checkout-index-index
      .modal-slide .modal-footer .secondary:focus:not([disabled]), body.checkout-index-index
      .modal-slide .modal-footer .secondary:active:not([disabled]), body.checkout-index-index
      .modal-popup .modal-footer .action-secondary:hover:not([disabled]), body.checkout-index-index
      .modal-popup .modal-footer .action-secondary:focus:not([disabled]), body.checkout-index-index
      .modal-popup .modal-footer .action-secondary:active:not([disabled]), body.checkout-index-index
      .modal-popup .modal-footer .secondary:hover:not([disabled]), body.checkout-index-index
      .modal-popup .modal-footer .secondary:focus:not([disabled]), body.checkout-index-index
      .modal-popup .modal-footer .secondary:active:not([disabled]), body.checkout-index-index
      .modal-custom .modal-footer .action-secondary:hover:not([disabled]), body.checkout-index-index
      .modal-custom .modal-footer .action-secondary:focus:not([disabled]), body.checkout-index-index
      .modal-custom .modal-footer .action-secondary:active:not([disabled]), body.checkout-index-index
      .modal-custom .modal-footer .secondary:hover:not([disabled]), body.checkout-index-index
      .modal-custom .modal-footer .secondary:focus:not([disabled]), body.checkout-index-index
      .modal-custom .modal-footer .secondary:active:not([disabled]) {
        box-shadow: none;
        outline: none;
        text-decoration: none;
        border: 1px solid #7A5B05;
        color: #fff;
        background-color: #7A5B05; }
        body.checkout-index-index .modal-slide .modal-footer .action-secondary:hover:not([disabled]) *, body.checkout-index-index .modal-slide .modal-footer .action-secondary:focus:not([disabled]) *, body.checkout-index-index .modal-slide .modal-footer .action-secondary:active:not([disabled]) *, body.checkout-index-index
        .modal-slide .modal-footer .secondary:hover:not([disabled]) *, body.checkout-index-index
        .modal-slide .modal-footer .secondary:focus:not([disabled]) *, body.checkout-index-index
        .modal-slide .modal-footer .secondary:active:not([disabled]) *, body.checkout-index-index
        .modal-popup .modal-footer .action-secondary:hover:not([disabled]) *, body.checkout-index-index
        .modal-popup .modal-footer .action-secondary:focus:not([disabled]) *, body.checkout-index-index
        .modal-popup .modal-footer .action-secondary:active:not([disabled]) *, body.checkout-index-index
        .modal-popup .modal-footer .secondary:hover:not([disabled]) *, body.checkout-index-index
        .modal-popup .modal-footer .secondary:focus:not([disabled]) *, body.checkout-index-index
        .modal-popup .modal-footer .secondary:active:not([disabled]) *, body.checkout-index-index
        .modal-custom .modal-footer .action-secondary:hover:not([disabled]) *, body.checkout-index-index
        .modal-custom .modal-footer .action-secondary:focus:not([disabled]) *, body.checkout-index-index
        .modal-custom .modal-footer .action-secondary:active:not([disabled]) *, body.checkout-index-index
        .modal-custom .modal-footer .secondary:hover:not([disabled]) *, body.checkout-index-index
        .modal-custom .modal-footer .secondary:focus:not([disabled]) *, body.checkout-index-index
        .modal-custom .modal-footer .secondary:active:not([disabled]) * {
          color: #fff;
          fill: #fff; }
      body.checkout-index-index .modal-slide .modal-footer .action-secondary[disabled], body.checkout-index-index
      .modal-slide .modal-footer .secondary[disabled], body.checkout-index-index
      .modal-popup .modal-footer .action-secondary[disabled], body.checkout-index-index
      .modal-popup .modal-footer .secondary[disabled], body.checkout-index-index
      .modal-custom .modal-footer .action-secondary[disabled], body.checkout-index-index
      .modal-custom .modal-footer .secondary[disabled] {
        opacity: 0.65;
        cursor: auto; }
      body.checkout-index-index .modal-slide .modal-footer .action-secondary span, body.checkout-index-index
      .modal-slide .modal-footer .secondary span, body.checkout-index-index
      .modal-popup .modal-footer .action-secondary span, body.checkout-index-index
      .modal-popup .modal-footer .secondary span, body.checkout-index-index
      .modal-custom .modal-footer .action-secondary span, body.checkout-index-index
      .modal-custom .modal-footer .secondary span {
        margin-top: 0;
        z-index: 1;
        font-weight: 700;
        vertical-align: baseline;
        display: inline-block;
        line-height: 1.275em;
        letter-spacing: normal; }
  .modal-slide .modal-footer button:only-child,
  .modal-popup .modal-footer button:only-child,
  .modal-custom .modal-footer button:only-child {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    will-change: transform, background-color, border-color, fill, color;
    min-width: 12rem; }
    .modal-slide .modal-footer button:only-child::-moz-focus-inner,
    .modal-popup .modal-footer button:only-child::-moz-focus-inner,
    .modal-custom .modal-footer button:only-child::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .modal-slide .modal-footer button:only-child, .modal-slide .modal-footer button:only-child:visited,
    .modal-popup .modal-footer button:only-child,
    .modal-popup .modal-footer button:only-child:visited,
    .modal-custom .modal-footer button:only-child,
    .modal-custom .modal-footer button:only-child:visited {
      cursor: pointer;
      border-radius: 3px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #7A5B05; }
      .modal-slide .modal-footer button:only-child *, .modal-slide .modal-footer button:only-child:visited *,
      .modal-popup .modal-footer button:only-child *,
      .modal-popup .modal-footer button:only-child:visited *,
      .modal-custom .modal-footer button:only-child *,
      .modal-custom .modal-footer button:only-child:visited * {
        color: #fff;
        fill: #fff; }
    .modal-slide .modal-footer button:only-child:hover:not([disabled]), .modal-slide .modal-footer button:only-child:focus:not([disabled]), .modal-slide .modal-footer button:only-child:active:not([disabled]),
    .modal-popup .modal-footer button:only-child:hover:not([disabled]),
    .modal-popup .modal-footer button:only-child:focus:not([disabled]),
    .modal-popup .modal-footer button:only-child:active:not([disabled]),
    .modal-custom .modal-footer button:only-child:hover:not([disabled]),
    .modal-custom .modal-footer button:only-child:focus:not([disabled]),
    .modal-custom .modal-footer button:only-child:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #7A5B05; }
      .modal-slide .modal-footer button:only-child:hover:not([disabled]) *, .modal-slide .modal-footer button:only-child:focus:not([disabled]) *, .modal-slide .modal-footer button:only-child:active:not([disabled]) *,
      .modal-popup .modal-footer button:only-child:hover:not([disabled]) *,
      .modal-popup .modal-footer button:only-child:focus:not([disabled]) *,
      .modal-popup .modal-footer button:only-child:active:not([disabled]) *,
      .modal-custom .modal-footer button:only-child:hover:not([disabled]) *,
      .modal-custom .modal-footer button:only-child:focus:not([disabled]) *,
      .modal-custom .modal-footer button:only-child:active:not([disabled]) * {
        color: #fff;
        fill: #fff; }
    .modal-slide .modal-footer button:only-child[disabled],
    .modal-popup .modal-footer button:only-child[disabled],
    .modal-custom .modal-footer button:only-child[disabled] {
      opacity: 0.65;
      cursor: auto; }
    .modal-slide .modal-footer button:only-child span,
    .modal-popup .modal-footer button:only-child span,
    .modal-custom .modal-footer button:only-child span {
      margin-top: 0;
      z-index: 1;
      font-weight: 700;
      vertical-align: baseline;
      display: inline-block;
      line-height: 1.275em;
      letter-spacing: normal; }

.modal-slide .modal-title,
.modal-popup .modal-title,
.modal-custom .modal-title {
  font-family: "CaviarDreams", "CaviarDreams";
  font-size: 1.8rem;
  color: #212121;
  font-weight: 700;
  text-transform: uppercase;
  margin: 1rem 0 2rem;
  padding: 2rem;
  border-bottom: 1px solid #dcdcdc;
  margin: 0; }

@media (max-width: 47.99em) {
  .modal-popup.modal-slide .modal-inner-wrap[class] {
    background-color: #fff; } }

@media (max-width: 29.99em) {
  .modal-slide .modal-footer {
    flex-wrap: wrap; } }

@media (max-width: 29.99em) {
  .modal-slide .modal-footer .action-primary,
  .modal-slide .modal-footer .primary {
    width: 100%; } }

@media (max-width: 29.99em) {
  .modal-slide .modal-footer .action-secondary,
  .modal-slide .modal-footer .secondary {
    width: 100%;
    margin-bottom: 1.5rem !important; } }

.ie11 .modal-popup:not(.cs-video-player__modal) .modal-content {
  max-height: calc(100vh - 33rem); }

.page-layout-checkout .modal-popup {
  pointer-events: auto; }

@media (min-width: 48em) {
  .page-layout-checkout .modal-slide {
    width: 80%;
    max-width: 65rem;
    left: 50%;
    transform: translateX(-50%); } }

.modals-overlay {
  background-color: rgba(0, 0, 0, 0.7); }

/* stylelint-enable */
