/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
html {
  box-sizing: border-box;
  font-size: 62.5%;
  background: #fff;
  /* stylelint-disable */
  overflow-x: hidden;
  /* stylelint-enable */ }

*,
*:before,
*:after {
  box-sizing: inherit; }

body {
  font-family: "CaviarDreams", "CaviarDreams";
  -webkit-overflow-scrolling: touch;
  margin: 0;
  font-size: 1.4rem;
  font-display: swap; }

button,
input,
textarea,
select {
  font-family: inherit; }

/* Hide HTML5 Up and Down arrows. */
input[type='number'] {
  appearance: textfield; }
  input[type='number']::-webkit-outer-spin-button, input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

/* Hide HTML5 'X' in search input */
input[type='search'] {
  /* Clears the 'X' from Internet Explorer */
  /* Clears the 'X' from Chrome */ }
  input[type='search']::-ms-clear, input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0; }
  input[type='search']::-webkit-search-decoration, input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-results-button, input[type='search']::-webkit-search-results-decoration {
    display: none; }
