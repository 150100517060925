@import 'config/variables';
@import 'utils/aspect-ratio';
@import 'vendors/include-media';

@import '../../../../theme-creativeshop/src/components/navigation/navigation.scss';

.#{$ns}navigation {
    $root: &;

    &__link {
        &--level {
            &_1 {
                text-transform: capitalize;
            }
        }
    }

    &__products {
        &-header {
            text-transform: capitalize;
        }
    }
}
