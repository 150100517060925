@import 'config/variables';
@import 'vendors/include-media';
@import 'components/header/user-nav/user-nav';

$offcanvas-toggle_icon-background: transparent !default;
$offcanvas-toggle_icon-color: $color_primary-500 !default;
$offcanvas-toggle_icon-background-active: $color_primary-500 !default;
$offcanvas-toggle_icon-color-active: $color_background-200 !default;
$offcanvas-toggle_icon-transition-time: $transition-default-time !default;
$offcanvas-toggle_animation-direction: 'left' !default; // 'left' or 'right'
$offcanvas-toggle_text-color: $color_text-800 !default;
$offcanvas-toggle_text-color-active: $color_text-200 !default;
$offcanvas-toggle_text-transform: uppercase !default;

.#{$ns}offcanvas-toggle {
    $root: &;

    position: relative;
    display: flex;
    border: 0;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    align-self: stretch;
    height: 100%;
    width: 100%;

    @if ($global_header-mobile-search-in-offcanvas) {
        width: 5.5rem;

        @include media(
            '>=#{$global_header-mobile-search-in-offcanvas-breakpoint}'
        ) {
            width: auto;
        }
    }

    &,
    &:active,
    &:focus,
    &:hover {
        border: 0;
        background: $offcanvas-toggle_icon-background;
        box-shadow: none;
        border-radius: 0;
    }

    &:after {
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        transform: scale(0);
        background: $offcanvas-toggle_icon-background-active;
        transition: transform $offcanvas-toggle_icon-transition-time;
        bottom: 0;

        @if ($offcanvas-toggle_animation-direction == 'right') {
            right: 0;
            transform-origin: right;
        } @else {
            left: 0;
            transform-origin: left;
        }
    }

    &--active {
        &,
        &:active,
        &:focus,
        &:hover {
            background: $offcanvas-toggle_icon-background-active;
        }

        &:after {
            transform: scale(1);
        }

        #{$root}__icon {
            transform: rotate(-45deg);

            &,
            &:before,
            &:after {
                background: $offcanvas-toggle_icon-color-active;
            }

            &:before,
            &:after {
                transform: rotate(90deg);
            }
        }
    }

    &__icon {
        padding: 0;
        margin: 0;
        position: relative;

        &,
        &:before,
        &:after {
            width: 1.8rem;
            height: 0.2rem;
            background: $offcanvas-toggle_icon-color;
            transition: background-color $offcanvas-toggle_icon-transition-time,
                transform $offcanvas-toggle_icon-transition-time;
        }

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
        }

        &:before {
            transform: translateY(-250%);
            transform: translate3d(0, -250%, 0);
        }

        &:after {
            transform: translateY(250%);
            transform: translate3d(0, 250%, 0);
        }

        @if ($global_header-mobile-search-in-offcanvas) {
            display: none;

            @include media(
                '>=#{$global_header-mobile-search-in-offcanvas-breakpoint}'
            ) {
                display: block;
            }

            #{$root}--active & {
                display: block;
            }
        }
    }

    &__text {
        position: absolute;
        bottom: 0.5rem;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 0.8rem;
        color: $offcanvas-toggle_text-color;
        text-transform: $offcanvas-toggle_text-transform;
        transition: color 0.3s;

        #{$root}--active & {
            color: $offcanvas-toggle_text-color-active;
        }

        @include media('>=tablet') {
            bottom: 2rem;
        }
    }

    @if ($global_header-mobile-search-in-offcanvas) {
        &__menu-search-icon {
            fill: $header-user-nav_icon-color;
            width: 2.6rem;
            height: 2.6rem;

            @include media(
                '>=#{$global_header-mobile-search-in-offcanvas-breakpoint}'
            ) {
                display: none;
            }

            &:hover {
                fill: $header-user-nav_icon-color-hover;
            }

            #{$root}--active & {
                display: none;
            }
        }
    }
}
