/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/*
 * Because price-box is used in multiple components like tile, buybox etc,
 * we need to move variables to separate file to have possibility to include them
 * in each component without generate price-box component styles multiple times
*/
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
.price-box {
  display: inline-block; }
  .price-box .price {
    font-weight: 700;
    white-space: nowrap; }
    .price-box .price-label {
      display: none; }
  .price-box .old-price .price {
    font-size: 0.65rem;
    text-decoration: line-through; }
  .price-box .old-price.no-display {
    display: none; }
  .price-box .special-price .price {
    padding-left: 0.4em; }
  .price-box .price {
    color: #7A5B05;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0; }
    .price-box .price-label {
      color: #7A5B05;
      display: none;
      font-size: 1.1rem;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0 0.3rem 0.3rem 0; }
      .price-box .price-label:after {
        content: ':'; }
      body
p
.price-box .price-label {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        white-space: nowrap;
        /* 1 */ }
  .price-box .price-wrapper {
    display: flex; }
  .price-box .price-from,
  .price-box .price-to {
    display: inline-block;
    margin: 0;
    padding: 0; }
    .price-box .price-from > .price-container,
    .price-box .price-to > .price-container {
      font-size: 1.1rem; }
    .price-box .price-from .price-label,
    .price-box .price-to .price-label {
      display: inline-block;
      padding: 0; }
  .price-box .old-price .price {
    text-decoration: line-through;
    color: #7A5B05;
    font-size: 1.5rem;
    font-weight: 700; }
  .price-box .special-price .price {
    padding-left: 0;
    color: #7A5B05;
    font-size: 1.5rem;
    font-weight: 700; }
  .price-box .special-price.active .price {
    color: #7A5B05; }
  .price-box .price-from {
    margin-right: 1rem; }
  .cs-price--pdp_dailydeal-countdown .price-box:not(.price-final_price_without_daily_deal) {
    display: none; }
  .price-box .price-container.price-final_price:not(:only-child) .price {
    color: #7A5B05; }

.cs-bundle-box__add .price-as-configured {
  margin: 1rem 0 0; }

.minimal-price-link {
  margin-top: 0 !important;
  margin-right: 1.5rem;
  position: relative; }
  .minimal-price-link:after {
    color: #7A5B05;
    display: block;
    content: '-';
    right: -1rem;
    top: 0;
    position: absolute; }
