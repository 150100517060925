/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* Radio and checkbox styling */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-newsletter {
  margin: 0;
  width: 100%; }
  @media (max-width: 47.99em) {
    .cs-newsletter {
      padding: 0 1.5rem; } }
  .cs-newsletter__form {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden; }
    @media (min-width: 48em) {
      .cs-newsletter__form {
        max-width: 34rem; } }
    @media (min-width: 64em) {
      .cs-newsletter__form {
        max-width: 100%; } }
  .cs-newsletter__form-wrapper {
    display: flex;
    flex-basis: 100%; }
  .cs-newsletter__field {
    flex-basis: 100%; }
  .cs-newsletter__input {
    margin-top: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .cs-newsletter__label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    /* 1 */ }
  .cs-newsletter__actions {
    display: flex;
    align-items: stretch;
    height: 4rem; }
  .cs-newsletter__button {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    will-change: transform, background-color, border-color, fill, color;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
    .cs-newsletter__button::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-newsletter__button, .cs-newsletter__button:visited {
      cursor: pointer;
      border-radius: 3px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #7A5B05; }
      .cs-newsletter__button *, .cs-newsletter__button:visited * {
        color: #fff;
        fill: #fff; }
    .cs-newsletter__button:hover:not([disabled]), .cs-newsletter__button:focus:not([disabled]), .cs-newsletter__button:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #7A5B05; }
      .cs-newsletter__button:hover:not([disabled]) *, .cs-newsletter__button:focus:not([disabled]) *, .cs-newsletter__button:active:not([disabled]) * {
        color: #fff;
        fill: #fff; }
    .cs-newsletter__button[disabled] {
      opacity: 0.65;
      cursor: auto; }
  .cs-newsletter__button-icon {
    max-width: 1em;
    max-height: 1.4rem;
    z-index: 2;
    height: auto;
    width: 100%;
    position: relative;
    top: 0.1em;
    width: 0.8em;
    height: 0.8em;
    margin-right: 0; }
