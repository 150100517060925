@import 'config/variables';
@import 'vendors/include-media';
@import 'components/container/mixin';

$header_background: $color_background-500;
$container_background-top-bar: $color_soft-peach;

@import '../../../../theme-creativeshop/src/components/container/container.scss';

.#{$ns}container {
    &--header {
        @include media('>=laptop') {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
        background-color: $color_gallery;
    }

    &--top-bar {
        background-color: $color_soft-peach;
        p {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }



}
