@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/visually-hidden';
@import 'components/qty-counter/mixin';

@import '../../../../../theme-creativeshop/src/components/header/user-nav/user-nav.scss';

.#{$ns}header-user-nav {
    $root: &;

    &__link {
        padding: 0.5rem;
    }

}
