/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
[class^='swatch-opt-'] {
  font-size: 0.45em;
  min-height: calc( 1 * (2.8em + 8px));
  opacity: 1;
  transition: opacity 0.3s;
  pointer-events: none; }
  @media (min-width: 48.01em) {
    [class^='swatch-opt-'] {
      pointer-events: auto; } }
  [class^='swatch-opt-']:empty {
    opacity: 0; }
  .ie11 [class^='swatch-opt-'] {
    width: 100%; }

.swatch-opt {
  font-size: 0.9em; }
  .swatch-opt .mage-error {
    font-size: 1em;
    width: 100%;
    margin-left: calc(10rem + 1.5rem); }
    @media (min-width: 64em) {
      .swatch-opt .mage-error {
        margin-left: calc( 11rem + 1.5rem); } }

.swatch-attribute {
  display: flex;
  width: 100%;
  overflow: hidden;
  align-items: center !important;
  flex-wrap: wrap; }
  .swatch-attribute.swatch-layered {
    font-size: 0.8em; }
  .swatch-attribute + .swatch-attribute {
    margin-top: 0.8em; }

.swatch-attribute-label {
  text-transform: uppercase;
  line-height: calc( 2.8em - 6px + 0.1em);
  font-weight: 700;
  font-size: 1.1em;
  width: 10rem;
  margin-right: 1.5rem; }
  @media (min-width: 64em) {
    .swatch-attribute-label {
      width: 11rem; } }
  .swatch-attribute-label:after {
    content: ': ';
    line-height: calc( 2.8em - 6px + 0.1em); }

.swatch-attribute-selected-option {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  /* 1 */ }

.swatch-attribute-options {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.2em;
  margin-bottom: -0.4em;
  min-width: 100%;
  justify-content: center; }
  .swatch-attribute-options:focus {
    box-shadow: none; }
  .swatch-opt .swatch-attribute-options {
    min-width: 0;
    justify-content: flex-start;
    max-width: calc( 100% - 1.5rem - 10rem); }
    @media (min-width: 64em) {
      .swatch-opt .swatch-attribute-options {
        max-width: calc( 100% - 1.5rem - 11rem); } }

.swatch-option-link-layered {
  font-size: 1em; }
  .swatch-option-link-layered:hover {
    text-decoration: none; }

.swatch-option {
  font-weight: normal;
  position: relative;
  min-width: 2.9em;
  height: 2.8em;
  padding: 0 0.35em;
  margin: 1px calc( 1px + 0.2em) calc(1px + 0.4em);
  border-radius: 100%;
  background-color: #fff;
  border: 3px solid #fff;
  color: #000;
  cursor: pointer;
  line-height: calc( 2.8em - 6px + 0.1em);
  text-align: center;
  transition: background-color 0.3s, border-color 0.3s, opacity 0.3s, color 0.3s;
  display: block !important; }
  .swatch-option:before {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border: 1px solid #dcdcdc;
    border-radius: 100%;
    transition: border-color 0.3s; }
  .swatch-option:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    background: url(../images/icons/swatches/disabled.svg) rgba(220, 220, 220, 0.5) center no-repeat;
    transform: translate(-50%, -50%);
    width: calc(2.8em - 1.4em);
    height: calc(2.8em - 1.4em);
    background-size: 0.6em;
    transition: opacity 0.3s;
    opacity: 0; }
  .swatch-option:not(.disabled):not(.selected):not([disabled]):hover {
    border: 3px solid #fff;
    background: #fff; }
    .swatch-option:not(.disabled):not(.selected):not([disabled]):hover:before {
      border: 1px solid #7A5B05; }
  .swatch-option.selected {
    border: 3px solid #fff;
    background: #fff; }
    .swatch-option.selected:before {
      border: 1px solid #7A5B05; }
  .swatch-option.disabled, .swatch-option[disabled] {
    cursor: not-allowed; }
    .swatch-option.disabled.text, .swatch-option[disabled].text {
      opacity: 0.65;
      color: #000;
      background: #fff; }
      .swatch-option.disabled.text:before, .swatch-option[disabled].text:before {
        border: 1px solid #dcdcdc; }
    .swatch-option.disabled.image:after, .swatch-option.disabled.color:after, .swatch-option[disabled].image:after, .swatch-option[disabled].color:after {
      opacity: 1; }

.swatch-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  /* 1 */
  left: -1000px;
  position: absolute;
  visibility: hidden; }

.swatch-more {
  display: none; }

.swatch-option-tooltip,
.swatch-option-tooltip-layered {
  display: none;
  position: absolute;
  margin: 0;
  z-index: 999;
  background: #fff;
  border-radius: 0.5rem;
  color: #000;
  font-size: 1.3rem;
  font-weight: 400;
  padding: 0.5rem;
  text-align: center;
  min-width: 2rem;
  border: 0.1rem solid #dcdcdc; }
  .swatch-option-tooltip .image,
  .swatch-option-tooltip-layered .image {
    display: block;
    margin: 0 auto;
    width: 110px;
    height: 110px; }
  .swatch-option-tooltip .corner:after,
  .swatch-option-tooltip-layered .corner:after {
    content: '';
    position: absolute;
    top: 100%;
    display: block;
    width: 1rem;
    height: 1rem;
    background-color: #fff;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    border-bottom: 0.1rem solid #dcdcdc;
    border-right: 0.1rem solid #dcdcdc;
    margin-top: calc( -0.5rem + 0.1rem); }

.swatch-option-tooltip {
  pointer-events: none; }

.missing-swatches-modal {
  max-width: 40rem;
  margin-left: auto; }
  .missing-swatches-modal .mage-error {
    padding-top: 0.8rem; }
  .missing-swatches-modal .swatch-attribute + .swatch-attribute {
    margin-top: 2rem; }
