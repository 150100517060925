@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/visually-hidden';
@import 'components/pseudoicon/mixin';
@import 'components/typography/mixin';

$footer_background: $color_alto;
$footer_text-color: $color_black;
$footer_logo-width: 10em;
$footer_logo-height: 10em;
$footer_custom_font_size: 1.1rem !default;

@import '../../../../theme-creativeshop/src/components/footer/footer.scss';

.#{$ns}footer {
    font-size: $footer_custom_font_size;
    background: $footer_background;

    &__item {

        &--promo {

            @if $footer_promo-display-on-mobile == false {
                @include media('<tablet') {
                    display: block;
                }
            }

            @include media('>=laptop') {
                flex-basis: auto;
                flex-grow: 1;
                padding-right: 2rem;
                width: 22rem;
            }

            @include media('<laptop') {
                text-align: center;
            }
        }

        &--links {
            @include media('>=laptop') {
                flex-basis: auto;
                flex-grow: 5;
                padding-right: 0;
            }
        }
    }

    &__section {
        @include media('>=tablet') {
            padding-right: 3.8rem;
        }

        &--newsletter {
            @include media('>=tablet') {
                padding-right: 2rem;
            }
        }
    }

    &__section-title,
    [data-role='title']{
        color: $color_yukon-gold;
        font-size: 1.8rem;
        font-weight: 700;
        text-transform: uppercase;
    }

    &__shop-logo {
        margin-bottom: 3rem;

        @include media('<laptop') {
            margin: auto;
            margin-bottom: 3rem;
            margin-top: 2rem;
        }
    }
}

